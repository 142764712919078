import ImageBlock from '@/components/shared/image-block'
import PropTypes from 'prop-types'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import imageLg from './images/astro-pi-lg.jpg'
import imageMd from './images/astro-pi-md.jpg'
import imageSm from './images/astro-pi-sm.jpg'

const heading = {
  text: 'Enter the European Astro Pi Challenge',
}

const image = {
  alt:
    'An astronaut in a spacesuit, with Earth, the moon and stars in the background',
  height: 800,
  sources: [
    {
      media: '(min-width: 1000px)',
      srcSet: imageLg,
    },
    {
      media: '(min-width: 800px)',
      srcSet: imageMd,
    },
    {
      srcSet: imageSm,
    },
  ],
  width: 1600,
}

const modifiers = {
  imageBottom: true,
}

const AstroPiCompetition = ({ children, eventCategory }) => (
  <Section>
    <ImageBlock
      backgroundColor="rgb(0, 43, 63)"
      data-sauce-inverted-text="true"
      heading={heading}
      image={image}
      modifiers={modifiers}
    >
      {children}

      <TrackedLink
        className="sc-rp-button"
        eventCategory={eventCategory}
        eventLabel="Astro Pi: astro-pi.org button"
        to="https://astro-pi.org"
      >
        Find out more
      </TrackedLink>
    </ImageBlock>
  </Section>
)

AstroPiCompetition.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  eventCategory: PropTypes.string.isRequired,
}

export default AstroPiCompetition
