import FreeCoursesImage from './images/free-computing-courses.png'
import ImageBlock from '@/components/shared/image-block'
import PropTypes from 'prop-types'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'

const heading = {
  text: 'Free computing courses',
}

const image = {
  alt: 'An illustration of a woman sitting at a computer desk',
  height: 718,
  sources: [
    {
      srcSet: FreeCoursesImage,
    },
  ],
  width: 1560,
}

const modifiers = {
  imageBottom: true,
}

const FreeComputingCourses = ({ children, eventCategory }) => (
  <Section>
    <ImageBlock
      backgroundColor="#ecfffb"
      heading={heading}
      image={image}
      modifiers={modifiers}
    >
      {children}

      <TrackedLink
        className="sc-rp-button"
        eventCategory={eventCategory}
        eventLabel="Free Computing Courses: courses button"
        to="https://www.raspberrypi.org/courses/learn-python"
      >
        Discover free courses
      </TrackedLink>
    </ImageBlock>
  </Section>
)

FreeComputingCourses.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  eventCategory: PropTypes.string.isRequired,
}

export default FreeComputingCourses
