/* eslint-disable no-console */
import PropTypes from 'prop-types'
import React from 'react'
import cx from 'bem-classnames'

const ImageBlock = ({
  backgroundColor,
  children,
  className,
  heading,
  image,
  modifiers,
  ...rest
}) => {
  const bemClasses = {
    name: 'sc-rp-image-block',
    modifiers: [
      'debug',
      'image-bottom',
      'image-top',
      'padded-image',
      'reverse',
    ],
  }

  const classNames = cx(
    bemClasses,
    {
      debug: modifiers.debug,
      'image-bottom': modifiers.imageBottom,
      'image-top': modifiers.imageTop,
      'padded-image': modifiers.paddedImage,
      reverse: modifiers.reverse,
    },
    className
  )

  const HeadingElement = heading?.element || 'h2'
  const hasHeading = heading?.text
  const style = { backgroundColor }

  return (
    <div className={classNames} style={style} {...rest}>
      <div className="sc-rp-image-block__content">
        {hasHeading && (
          <HeadingElement className="sc-rp-type-secondary-heading">
            {heading.text}
          </HeadingElement>
        )}

        {children}
      </div>

      <div className="sc-rp-image-block__figure">
        <picture className="sc-rp-image-block__image-container">
          {image.sources.map((source, index) => (
            <source key={index} {...source} />
          ))}

          <img
            alt={image.alt}
            className="sc-rp-image-block__image"
            height={image.height}
            src={image.sources[0].srcSet}
            width={image.width}
          />
        </picture>
      </div>
    </div>
  )
}

ImageBlock.defaultProps = {
  modifiers: {
    debug: false,
    imageBottom: false,
    imageTop: false,
    paddedImage: false,
    reverse: false,
  },
}

ImageBlock.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  heading: PropTypes.shape({
    element: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.string,
        srcSet: PropTypes.string.isRequired,
      })
    ).isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  modifiers: PropTypes.shape({
    debug: PropTypes.bool,
    imageBottom: PropTypes.bool,
    imageTop: PropTypes.bool,
    paddedImage: PropTypes.bool,
    reverse: PropTypes.bool,
  }),
}

export default ImageBlock
