import './stats.scss'

import Container from '@/components/shared/container'
import React from 'react'
import Section from '@/components/shared/section'
import image1 from './images/facts-1.jpg'
import image2 from './images/facts-2.jpg'
import image3 from './images/facts-3.jpg'

const Stats = () => (
  <Section>
    <Container backgroundColor="rgb(225, 129, 140)">
      <div className="c-teach-stats">
        <div className="c-teach-stats__media">
          <img alt="" src={image1} width="1000" height="645" />
          <img alt="" src={image2} width="855" height="1000" />
          <img alt="" src={image3} width="1000" height="780" />
        </div>

        <ul className="c-teach-stats__list">
          <li>
            <strong>92%</strong> of educators and volunteers say young people
            involved in our programmes have improved their programming skills.
          </li>

          <li>
            <strong>93%</strong> of teachers say our curriculum resources
            are effective or very effective at supporting them.
          </li>

          <li>
            We’ve been using <strong>quizzes and rewards</strong> to trial how
            to assess skills development in our programmes.
          </li>
        </ul>
      </div>
    </Container>
  </Section>
)

export default Stats
