import './teach.scss'

import ApplicationLayout from '@/Layouts/Application'
import AstroPiCompetition from '@/components/shared/AstroPiCompetition'
import EducationNewsletter from '@/components/teach/education-newsletter'
import FreeComputingCourses from '../../components/shared/FreeComputingCourses'
import GenderBalanceInComputing from '@/components/teach/gender-balance-in-computing'
import HelloWorldMagazine from '@/components/teach/hello-world-magazine'
import Hero from '@/components/teach/hero'
import PageMeta from '@/components/Application/PageMeta'
import React from 'react'
import Stats from '@/components/teach/stats'
import opengraphImageSrc from './images/opengraph.png'
import pageMeta from './config/meta.json'

export const TeachPage = () => {
  const { description, title } = pageMeta

  return (
    <ApplicationLayout>
      <PageMeta
        description={description}
        image={opengraphImageSrc}
        title={title}
      />
      <Hero />
      <Stats />
      <AstroPiCompetition eventCategory="Signpost: Teach">
        <p>
          Help your students code scientific experiments that run on Raspberry
          Pi computers aboard the International Space Station.
        </p>
      </AstroPiCompetition>
      <FreeComputingCourses eventCategory="Signpost: Teach">
        <p>
          Our online courses are free and have something for everyone. No
          previous experience is needed. You can start learning a valuable new
          skill to use in your classroom or take a course to improve your
          subject knowledge.
        </p>
      </FreeComputingCourses>
      <HelloWorldMagazine />
      <GenderBalanceInComputing />
      <EducationNewsletter />
    </ApplicationLayout>
  )
}

export default TeachPage
