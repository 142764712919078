import './hello-world-magazine.scss'

import Container from '@/components/shared/container'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'

const HelloWorldMagazine = () => (
  <Section>
    <Container className="c-teach-hello-world-magazine">
      <div className="c-teach-hello-world-magazine__content">
        <h2 className="sc-rp-type-secondary-heading">Hello World magazine</h2>

        <p>Our free computing and digital making magazine for educators.</p>

        <p>
          One hundred pages of in-depth discussion, project ideas, lesson plans,
          news, and reviews. Available in digital and print.
        </p>

        <TrackedLink
          className="sc-rp-button"
          eventCategory="Signpost: Teach"
          eventLabel="Hello World Magazine: CTA Button"
          to="https://www.raspberrypi.org/hello-world"
        >
          Subscribe
        </TrackedLink>
      </div>
    </Container>
  </Section>
)

export default HelloWorldMagazine
