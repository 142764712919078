import PropTypes from 'prop-types'
import React from 'react'
import cx from 'bem-classnames'

const Container = ({ backgroundColor, children, className, ...rest }) => {
  const classNames = cx(['sl-container', className])
  const style = backgroundColor && { backgroundColor }

  return (
    <div className={classNames} style={style} {...rest}>
      {children}
    </div>
  )
}

Container.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default Container
