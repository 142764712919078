import './hero.scss'

import React from 'react'

const Hero = () => (
  <header className="c-teach-hero">
    <h1 className="c-teach-hero__title">Teachers & educators</h1>

    <div className="c-teach-hero__content">
      <p className="c-teach-hero__copy">
        Computing is a vital aspect of today’s world. We want to empower
        teachers to give young people the skills they need to shape this world.
      </p>

      <p className="c-teach-hero__copy">
        That’s why we provide high-quality CPD training courses, classroom
        resources, and online events for teachers. All for free.
      </p>
    </div>
  </header>
)

export default Hero
