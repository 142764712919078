import PropTypes from 'prop-types'
import React from 'react'
import cx from 'bem-classnames'

const Section = ({ children, className, ...rest }) => {
  const classNames = cx(['sl-section', className])

  return (
    <section className={classNames} {...rest}>
      {children}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default Section
