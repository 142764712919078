import ImageBlock from '@/components/shared/image-block'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import imageLg from './images/women-lg.jpg'
import imageMd from './images/women-md.jpg'
import imageSm from './images/women-sm.jpg'

const heading = {
  text: 'Gender Balance in Computing',
}

const image = {
  alt: 'A teacher and student at a laptop',
  height: 800,
  sources: [
    {
      media: '(min-width: 1000px)',
      srcSet: imageLg,
    },
    {
      media: '(min-width: 760px)',
      srcSet: imageMd,
    },
    {
      srcSet: imageSm,
    },
  ],
  width: 1600,
}

const GenderBalanceInComputing = () => (
  <Section>
    <ImageBlock
      backgroundColor="rgb(255, 238, 176)"
      heading={heading}
      image={image}
    >
      <p>
        We’re working to find the best ways to encourage young women to
        study computing.
      </p>
      <p>
        More than 1000 schools were part of our series of research trials
        testing teaching approaches to do this.
      </p>

      <TrackedLink
        className="sc-rp-button"
        eventCategory="Signpost: Teach"
        eventLabel="Gender Balance In Computing: CTA button'"
        to="https://www.raspberrypi.org/research/gender-balance-in-computing/"
      >
        Find out more
      </TrackedLink>
    </ImageBlock>
  </Section>
)

export default GenderBalanceInComputing
