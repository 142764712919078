import './education-newsletter.scss'

import React from 'react'
import Section from '@/components/shared/section'
import image from './images/education-newsletter.jpg'
import imageWide from './images/education-newsletter-wide.jpg'

const EducationNewsletter = () => (
  <Section>
    <div id="newsletter" className="c-teach-education-newsletter">
      <div className="c-teach-education-newsletter__container">
        <div className="c-teach-education-newsletter__content">
          <h2 className="sc-rp-type-secondary-heading">Learn newsletter</h2>

          <p>
            Keep up with all of the education news from the Raspberry Pi
            Foundation.
          </p>

          <form
            id="f-education-newsletter"
            className="c-teach-education-newsletter__form"
            action="//www3.raspberrypi.org/l/800983/2022-12-08/4dx2f4"
            method="post"
            target="_blank"
          >
            <div>
              <label htmlFor="f-education-newsletter__email">Your email</label>
              <input
                id="f-education-newsletter__email"
                name="email"
                placeholder="joanna@bloggs.com"
                required
                type="email"
              />
            </div>

            <button className="sc-rp-button">Subscribe</button>
          </form>
        </div>

        <div className="c-teach-education-newsletter__media">
          <picture>
            <source media="(min-width: 900px)" srcSet={image} />

            <img alt="" src={imageWide} width="820" height="320" />
          </picture>
        </div>
      </div>
    </div>
  </Section>
)

export default EducationNewsletter
